<template>
  <div>
    <div class="page-content border-bottom">
      <!-- 顶部搜索框 -->
      <div>
        <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
          <!-- 城市选择 -->
          <el-form-item label="城市">
            <el-input
              v-model="searchInfo.city"
              placeholder="请输入城市"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 手机号选择 -->
          <el-form-item label="手机号">
            <el-input
              v-model="searchInfo.phone"
              placeholder="请输入手机号"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="用户id">
            <el-input
              v-model="searchInfo.id"
              placeholder="请输入用户id"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="姓名" style="margin-top: 15px">
            <el-input
              v-model="searchInfo.name"
              placeholder="请输入姓名"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item class="right-btns" style="margin-top: 15px">
            <!-- <el-button type="primary" @click="onAddUser">新增</el-button> -->
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格展示区域 -->
    <keepAlive>
      <div class="page-content margin-top">
        <!-- 表格组件 -->
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        >
          <!-- 循环展示表格 -->
          <el-table-column label="序号" width="70" align="left">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            v-for="tab in tableTitle"
            :key="tab.code"
            :label="tab.name"
            show-overflow-tooltip
            :width="tab.width"
          >
            <template slot-scope="scope">
              <span>{{ scope.row[tab.code] }} </span>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <span @click="getItemDetail(scope.row)" class="update-btn"
                >查看</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </keepAlive>
    <!-- 新增账号弹框
    <el-dialog
      :title="isUpdate ? '修改' : '新增'"
      :visible.sync="dialogVisible"
      top="5vh"
      width="672px"
    >
      <el-form
        ref="form"
        class="dialog-form"
        :model="accountEdit"
        label-width="80px"
      >
        <el-form-item label="上传图标">
          <UploadImg :limit="1" @handleChangeImg="handleChangeImg" />
        </el-form-item>
        <el-form-item label="账号">
          <el-input
            v-model="accountEdit.number"
            placeholder="请输入账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            v-model="accountEdit.pwd"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="地区">
          <AreaSelect @handleChange="changeDialogArea" />
        </el-form-item>
        <el-form-item label="标题设置">
          <el-input
            v-model="accountEdit.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmitDialog">确定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { getBusinessesList, SearchBusinessesList } from "../../../api/common";
import { removeEmptyValues } from "../../../utils/removeEmptyValues";

export default {
  components: {
    // Pagination,
    // AreaSelect,
    // UploadImg,
  },
  data() {
    return {
      // 查询数据
      searchInfo: {
        city: "",
        name: "",
        id: "",
        phone: "",
        // page: 1,
        // size: 10,
      },
      // 弹框表单信息
      accountEdit: {
        number: "",
        pwd: "",
        area: "",
        title: "",
      },
      // 表格数据
      tableData: [],
      itemDetail: {},
      // 数据总数
      dataTotal: 100,
      // 标记是否新增
      isUpdate: false,
      // 是否展示弹框
      dialogVisible: false,
      // 表格标题数组
      tableTitle: [
        { name: "商户ID", code: "id", width: "" },
        { name: "姓名", code: "name", width: "" },
        { name: "城市", code: "city", width: "" },
        { name: "手机号", code: "phone", width: "" },
        { name: "工单上限", code: "setMeal", width: "" },
        { name: "已下发工单数", code: "issue", width: "120" },
        { name: "剩余工单数", code: "ticketsNumber", width: "" },
        { name: "申诉数", code: "appeal", width: "" },
        { name: "申诉成功", code: "appealSucceed", width: "" },
        { name: "已成交", code: "turnover", width: "" },
      ],
    };
  },
  mounted() {
    // 查询数据
    this.onGetDataList();
  },
  methods: {
    // 切换省市区触发
    changeArea(area) {
      this.searchInfo.address = area.join("");
      this.onGetDataList();
    },
    // 切换弹框中的省市区
    changeDialogArea(area) {
      console.log(area);
    },
    // 上传图片触发
    handleChangeImg(imglist) {
      console.log(imglist);
    },
    // 查询
    onSearch() {
      // this.searchInfo.page = 1
      const result = removeEmptyValues(this.searchInfo);
      SearchBusinessesList(result).then((res) => {
        this.tableData = res.data.queryResult.list;
        this.tableData.forEach((item) => {
          item.turnover = item.turnover == null ? 0 : item.turnover;
          item.appeal = item.appeal == null ? 0 : item.appeal;
          item.ticketsNumber = item.setMeal - item.turnover;
          item.appealSucceed =
            item.appealSucceed == null ? 0 : item.appealSucceed;
        });
        console.log(JSON.stringify(this.tableData));
      });
    },
    // 重置
    onReset() {
      this.onGetDataList();
    },
    // 新增方法
    onAddUser() {
      this.isUpdate = false;
      this.dialogVisible = true;
    },
    // 更新数据
    updateRow(row) {
      this.isUpdate = true;
      this.dialogVisible = true;
      console.log(row);
    },
    // 删除数据
    deleteRow(row) {
      console.log(row);
    },
    // 核心查询数据方法
    onGetDataList() {
      // 查询数据方法
      getBusinessesList().then((res) => {
        this.tableData = res.data.queryResult.list;
        this.tableData.forEach((item) => {
          item.phone = item.phone
            ? item.phone.slice(0, 3) + "****" + item.phone.slice(7, 11)
            : "——";
          item.registrationTime = item.registrationTime
            ? item.registrationTime.slice(0, 7)
            : "——";
          item.appeal = item.appeal == null ? "0" : item.appeal;
          item.appealSucceed =
            item.appealSucceed == null ? "0" : item.appealSucceed;
          item.turnover = item.turnover == null ? "0" : item.turnover;
          item.ticketsNumber = item.setMeal - item.issue;
        });
      });
    },
    // 弹框确认方法
    onSubmitDialog() {
      console.log(this.accountEdit);
    },

    // 查看数据详情
    getItemDetail(row) {
      localStorage.setItem("id", row.id);
      console.log("row.id" + row.id);
      this.$router.push({
        name: "AccountDetail",
        path: "account/detail",
        params: { id: row.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form-item-width {
  width: 250px;
}
.right-btns {
  position: absolute;
  right: 20px !important;
}
.el-form-item {
  margin-bottom: 4px !important;
}
.border-bottom {
  border-bottom: 1px solid #e2e2e4;
}
.margin-top {
  margin-top: -15px;
}
.update-btn {
  cursor: pointer;
  color: #409eff;
}
.delete-btn {
  cursor: pointer;
  color: #f56c6c;
  margin-left: 20px;
}
.dialog-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
</style>
